// jshint ignore: start
import React, { useState, useRef, useEffect } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles';
import MyInput from '../tools/MyInput'
import { globalHistory } from "@reach/router"
import EmailValidator from 'email-validator';
import queryString from 'query-string'
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { useIntl } from 'gatsby-plugin-intl';
import getFirebase from '../components/firebase';
const firebase = getFirebase();

const useStyles = makeStyles(theme => ({
    formroot: {
        width: 170,
        marginTop: 10,
        marginBottom: 20
    },
    selectRoot: {
        paddingRight: 24,
    },
    margin: {
        marginRight: 24
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    ya: {
        fontSize: 18,
        textAlign: 'center',
        letterSpacing: 2
    }
}));

function UnsubsContent() {
    const mypath = globalHistory.location.pathname
    const qs = queryString.parse(globalHistory.location.search)
    const myEmail = () => {
        if (EmailValidator.validate(qs.email)) {
            return qs.email
        }
        return ""
    }
    const [email, setEmail] = useState("")
    const [token, setToken] = useState("")
    const [list, setList] = useState("")
    const [password, setPassword] = useState("")
    const [disabled, setDisabled] = useState(false)
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [success, setSuccess] = useState(false);
    const [unsub, setUnsub] = useState(false);
    const [wrong, setWrong] = useState(false);
    const timer = useRef();
    const timer2 = useRef();
    const intl = useIntl();

    const handleInput = (e) => {
        setWrong(false)
        setPassword(e.target.value)
    }
    
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });
    const handleSendMail = () => {
        if (success) {
            return;
        }
        if (!loading) {
            setDisabled(true)
            setSuccess(false);
            setLoading(true);
            const query = {
                tk: "ip3fsU3J*8ue&CK6Cpo0cF35",
                email: email ? email : "",
                list: list,
                token: token
            }
            firebase.functions().httpsCallable('subscribeMail')({
                query
            }).then(result => {
                if (result.data) {
                    if (result.data.code === 400) {
                        setSuccess(true);
                        setLoading(false);
                    } else if (result.data.code === 401) {
                        setSuccess(false);
                        setLoading(false);
                    }
                    clearTimeout(timer.current);
                }
            });
            timer.current = setTimeout(() => {
                setSuccess(false);
                setLoading(false);
            }, 10000);
            // setSuccess(false);
            // setLoading(false);
        }
    };
    

    const handleUnsub = () => {
        setLoading2(true);
        const query = {
            tk: "ip3fsU3J*8ue&CK6Cpo0cF35",
            email: email ? email : "",
            list: list,
            token: token,
            code: password
        }
        firebase.functions().httpsCallable('unsubscribeMail')({
            query
        }).then(result => {
            if (result.data) {
                if (result.data.code === 400) {
                    setUnsub(true)
                    setLoading2(false);
                } else if (result.data.code === 401) {
                    setUnsub(false)
                     setLoading2(false);
                } else if (result.data.code === 402) {
                    setWrong(true)
                    setUnsub(false)
                    setLoading2(false);
                }
                clearTimeout(timer2.current);
            }
        });
        timer2.current = setTimeout(() => {
            setSuccess(false);
            setLoading2(false);
        }, 10000);
    }

    function authUser() {
        return new Promise(function (resolve, reject) {
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    resolve(user);
                } else {
                    reject('User not logged in');
                    var gu = localStorage.getItem("gatsbyUser");
                    if (gu) {
                        localStorage.setItem("gatsbyUser", JSON.stringify({}));
                    }
                }
            });
        });
    }

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        if (!ref.current) {
          ref.current = true;
          setEmail(myEmail)
          setToken(qs.token ? qs.token : "")
          setList(qs.list ? qs.list : "")
          authUser().then((user) => {
 
          }, (e) => {
            console.log(e);
          });
        }
        ref.current = value
      }, [value]);
      return ref.current;
    }

    const prevValue = usePrevious({});
    useEffect(() => {
      if (prevValue) {
      
      }
    }, [])

    useEffect(() => {
      return () => {
        clearTimeout(timer.current);
        clearTimeout(timer2.current);
      }
    }, []);

    return (
        <div>
            <SEO title="Unsubscribe" />
            <h1>{intl.formatMessage({id: "uns.unsubscribe"})}</h1>
            { !unsub ?
            <React.Fragment>
                <p style={{fontSize: 18}}>
                    {intl.formatMessage({id: "uns.important"})}
                </p>
                <div className={classes.wrapper}>
                    <Button
                    variant="contained"
                    color="primary"
                    className={buttonClassname}
                    disabled={loading || disabled || !email}
                    onClick={handleSendMail}
                    >

                    {!success ? intl.formatMessage({id: "uns.requestcode"}) : intl.formatMessage({id: "uns.sentcode"})}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                <FormControl className={classes.formroot}>
                    <InputLabel error={wrong} style={{transformOrigin: 'top center', width: '100%'}} shrink htmlFor="twitter-input3">
                        {wrong ? intl.formatMessage({id: "uns.wrongcode"}) : intl.formatMessage({id: "uns.entercode"})}
                    </InputLabel>
                    <MyInput disabled={!success} classes={{input: classes.ya}} onChange={handleInput} value={password} id="subject" style={{width: '100%'}}/>
                </FormControl>
                <div className={classes.wrapper}>
                    <Button
                        onClick={handleUnsub}
                        disabled={!password || !success}
                        variant="contained">
                            {intl.formatMessage({id: "uns.unsubscribe"})}
                    </Button>
                    {loading2 && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </React.Fragment>
            :
            <p style={{fontSize: 18, color: 'green'}}>{intl.formatMessage({id: "uns.unsubemail"})}{' '}<strong>{email}</strong></p>
            }
        </div>
    )
}

const UnsubscribePage = () => (
  <Layout>
    <div style={{textAlign: 'center', width: 500,marginLeft: 'auto',marginRight: 'auto'}}>
        <UnsubsContent/>
    </div>
  </Layout>
)

export default UnsubscribePage
